import React, { useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";
import { connect } from "react-redux";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import navigate from "~/components/navigate";
import Layout from "~/components/layout";
import SEO from "~/components/seo";
import LoginForm from "~/components/auth/loginForm";
import { getParameterByName } from "~/utils/app-helpers";

function LoginPage(props) {
  const paths = props.pageContext.paths;
  const i18n = props.i18n;

  const error = getParameterByName("err");
  const msg = getParameterByName("msg");

  const [section, setSection] = useState();

  const [logged, setLoginStatus] = useState(null);

  const successMsg = (msg) => {
    switch (msg) {
      case "pass_updated":
        return (
          <Trans>Password updated, please log in with your new password.</Trans>
        );
      case "logout":
        return <Trans>Log out successfully</Trans>;

      default:
        return null;
    }
  };

  const errorMsg = (error) => {
    switch (error) {
      case "23":
        return <Trans>Session invalid, please log in again.</Trans>;
      case "14":
        return <Trans>Session expired, please log in again.</Trans>;

      default:
        if (typeof num1 == "number") {
          Bugsnag.notify(error);
        }
        return null;
    }
  };

  useEffect(() => {
    if (props.auth.email) {
      navigate("/", i18n.language);
      return () => {
        return false;
      };
    } else setLoginStatus(false);

    switch (section) {
      case "forgot":
        navigate("/forgot", i18n.language);
        break;
      case "register":
        navigate("/register", i18n.language);
        break;

      default:
        return () => {
          return false;
        };
    }

    return () => {
      return false;
    };
  }, [section, i18n.language, props.auth.email]);

  if (logged === null) {
    return null;
  }

  return (
    <Layout paths={paths} hideFooter={true} className="h-screen">
      <SEO title={i18n._(t`Login into your account`)} location={paths} />

      <div className="flex md:items-center md:margin-auto justify-center h-mobile md:h-desktop">
        <div className="px-4 pt-8 min-w-full md:pt-0 md:min-w-1/2 lg:min-w-1/3  xl:min-w-1/4">
          <LoginForm
            changeSection={setSection}
            hideModal={() => {
              return false;
            }}
            successMsg={successMsg(msg)}
            errorMsg={errorMsg(error)}
            fromPage={true}
          />
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = ({ auth }) => {
  return {
    auth,
  };
};

export default withI18n()(connect(mapStateToProps)(LoginPage));
